<mat-progress-bar *ngIf="loading" class="color-progress" mode="indeterminate"></mat-progress-bar>
<mat-sidenav-container id="sidenavContainer" class="bg-gray-100" hasBackdrop=false>
    <mat-sidenav *ngIf="enabled" id="sidenav" class="bg-gray-100" opened [ngClass]="{'retract' : retract}">
        <app-sidenav></app-sidenav>
    </mat-sidenav>
  
    <mat-sidenav-content id="sidenavContent" class="bg-gray-100" [ngClass]="{'retract' : retract, 'disabled': !enabled}">
        <router-outlet></router-outlet>
        <app-support-button></app-support-button>
    </mat-sidenav-content>
</mat-sidenav-container>
<app-alert *ngIf="this.alertEvent.getStatus()" class="animate__animated animate__delay-0.5s"
    [class.animate__slideInRight]="!this.alertEvent.getRequestClose() && this.alertEvent.getStatus() && !this.alertEvent.getAlertMobile()"
    [class.animate__bounceOutRight]="this.alertEvent.getRequestClose() && !this.alertEvent.getAlertMobile()"
    [class.animate__slideInUp]="!this.alertEvent.getRequestClose() && this.alertEvent.getStatus() && this.alertEvent.getAlertMobile()"
    [class.animate__bounceOutDown]="this.alertEvent.getRequestClose() && this.alertEvent.getAlertMobile()"></app-alert>