import { Injectable } from "@angular/core";
import * as moment from "moment";
import { OperationModel, WeekOperationModel } from "src/app/models/dashboard.model";

@Injectable({
    providedIn: 'root'
})

export class FormatDataChartService {
    month: string[] = ['', 'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];

    formatEvolution(operations: OperationModel[], valueAccount: number){
        if(operations){
            let sum = valueAccount;
            let result: any[] = [['Saldo inicial', sum]]
            result = result.concat(operations.map(item => [item.operation_date? moment(item.operation_date).format("DD/MM/YYYY"):(`${this.month[item.month]}/${item.year}`), sum += Number(item.value)]));

            return result;
        }
        return [];
    }

    formatDay(operations: OperationModel[]){
        if(operations){
            let sum = 0,
            ops: any = operations.map(item => [item.asset, sum += Number(item.value)]);
            return [['', 0]].concat(ops);
        }
        return [];
    }

    formatWeek(operations: WeekOperationModel[]){
        if(operations){
            return operations.map(item => [moment(item.date).format("DD/MM/YYYY"), Number(item.total)]);
        }
        return [];
    }

    consGraph(value: number, len: number, description: string){
        let data = [];
        for(let i=0; i< len + 1;i++){
            data.push([description, Number(value)]);
        }
        return data;
    }
}