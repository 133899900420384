import { Component, Input } from '@angular/core';
import { PerformanceCommand } from 'src/app/commands/performance.command';
import { OperatingDetailModel } from 'src/app/models/operating-detail.model';

@Component({
  selector: 'app-table-operations',
  templateUrl: './table-operations.component.html',
  styleUrls: ['./table-operations.component.scss']
})
export class TableOperationsComponent {
  @Input() account: number;
  listOperations: OperatingDetailModel[] = [];
  pagination = {
    status: false,
    next: '',
    prev: '',
  };
  count: number;

  constructor(
    private performanceCommand: PerformanceCommand,
  ){}

  ngOnInit() {
    this.performanceCommand.operations(this.account).then((result: any) => {
      if(result && result.results){
        this.count = result.count;
        this.listOperations = result.results as OperatingDetailModel[];
      }
      if(result.next || result.previous){
        this.pagination.status = true;
        this.pagination.next = result.next ?? '';
        this.pagination.prev = result.previous ?? '';
      }
    });
  }

  alterPage(page = null){
    if(page){
      this.performanceCommand.page(page).then((result: any) => {
        this.listOperations = result.results as OperatingDetailModel[];
        if(result.next || result.previous){
          this.pagination.status = true;
          this.pagination.next = result.next ?? '';
          this.pagination.prev = result.previous ?? '';
        }
      });
    }
  }
}
