import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentModel } from 'src/app/models/payment.model';
import { PlanModel } from 'src/app/models/plan.model';

@Component({
  selector: 'app-boleto',
  templateUrl: './boleto.component.html',
  styleUrls: ['./boleto.component.scss']
})
export class BoletoComponent {
  boleto: string = "";
  plan: PlanModel;
  payment: PaymentModel;
  nav: any;
  loading: boolean = false;
  metaTraderValue: number = 0;
  blackArrowValue: number = 120;

  constructor(
    private router: Router,
    private location: Location,
  ) {
    this.nav = this.router.getCurrentNavigation();
    this.boleto = this.nav.extras.state? this.nav.extras.state.boleto as string: null;
    this.plan = this.nav.extras.state? this.nav.extras.state.plan as PlanModel: null;
    this.payment = this.nav.extras.state? this.nav.extras.state.payment as PaymentModel: null;
    this.blackArrowValue = this.plan.value_black_arrow;

    if(!this.boleto || !this.plan)
      this.location.back();
  }

  openLink(url: string) {
    window.open(url, "_blank");
  }
}
