import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccountOperationCommand } from 'src/app/commands/account-operation.command';
import { DashboardCommand } from 'src/app/commands/dashboard.command';
import { PerformanceCommand } from 'src/app/commands/performance.command';
import { AccountOperationModel } from 'src/app/models/account-operation.model';
import { DashboardModel } from 'src/app/models/dashboard.model';
import { PerformanceModel } from 'src/app/models/performance.model';

@Component({
  selector: 'app-rules-objectives',
  templateUrl: './rules-objectives.component.html',
  styleUrls: ['./rules-objectives.component.scss']
})
export class RulesObjectivesComponent {
  listAccounts: AccountOperationModel[] = [];
  selectedAccount: string;
  account_operation: AccountOperationModel;
  dashboard: DashboardModel;
  performance: PerformanceModel;
  nav: any;

  constructor(
    private accountOperationCommand: AccountOperationCommand,
    private dashboardCommand: DashboardCommand,
    private performanceCommand: PerformanceCommand,
    private router: Router,
  ){
    this.nav = this.router.getCurrentNavigation();
    if(this.nav.extras.state && this.nav.extras.state.account){
      setTimeout(() => {
        this.selectedAccount = this.nav.extras.state.account;
        this.seach();
      }, 500);
    }
  }

  ngOnInit() {
    this.accountOperationCommand.get().then((result: AccountOperationModel[]) => {
      if (result) {
        this.listAccounts = result;
      }
    });
  }

  seach() {
    Promise.all([
      this.dashboardCommand.get(this.selectedAccount),
      this.performanceCommand.get(this.selectedAccount),
    ]).then((data: any) => {
      if (data[0] && data[1]) {
        this.dashboard = data[0];
        this.performance = data[1];
        this.account_operation = this.dashboard.account_info;
      }
    });
  }
}
