import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LoginComponent } from './pages/login/login.component';
import { GenerateNewPasswordComponent } from './pages/generate-new-password/generate-new-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { UserDataComponent } from './pages/user-data/user-data.component';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { LoggedGuard } from './guards/logged.guard';
import { AnonymousGuard } from './guards/anonymous.guard';
import { MaintenanceGuard } from './guards/maintenance.guard';
import { MetavisionComponent } from './pages/metavision/metavision.component';
import { AccountConfirmComponent } from './pages/account-confirm/account-confirm.component';
import { ActiveNotificationsComponent } from './pages/active-notifications/active-notifications.component';
import { ActiveAlgotradingComponent } from './pages/active-augotrading/active-algotrading.component';
import { UserRegisterComponent } from './pages/user-register/user-register.component';
import { UpdateMetavisionComponent } from './pages/update-metavision/update-metavision.component';
import { FaqComponent } from './pages/faq/faq.component';
import { PerformanceComponent } from './pages/performance/performance.component';
import { PlansComponent } from './pages/plans/plans.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PaymentMessageComponent } from './pages/payment/payment-message/payment-message.component';
import { EnabledAccountComponent } from './pages/enabled-account/enabled-account.component';
import { CreditCardComponent } from './pages/credit-card/credit-card.component';
import { HistoryTransactionsComponent } from './pages/history-transactions/history-transactions.component';
import { RulesObjectivesComponent } from './pages/rules-objectives/rules-objectives.component';
import { AssetsAllowedComponent } from './pages/assets-allowed/assets-allowed.component';
import { EnabledAccountManualComponent } from './pages/enabled-account-manual/enabled-account-manual.component';
import { ImportComponent } from './pages/import/import.component';
import { PixComponent } from './pages/payment/pix/pix.component';
import { BoletoComponent } from './pages/payment/boleto/boleto.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    canActivate: [LoggedGuard],
    component: HomeComponent,
  },
  {
    path: 'planos',
    canActivate: [LoggedGuard],
    component: PlansComponent,
  },
  {
    path: 'login', 
    canActivate: [AnonymousGuard],
    component: LoginComponent,
  },
  {
    path: 'gerar-nova-senha', 
    component: GenerateNewPasswordComponent,
  },
  {
    path: 'alterar-senha', 
    canActivate: [LoggedGuard],
    component: ChangePasswordComponent,
  },
  {
    path: 'recuperar-senha', 
    canActivate: [AnonymousGuard],
    component: RecoverPasswordComponent,
  },
  {
    path: 'confirmar-conta', 
    component: AccountConfirmComponent,
  },
  {
    path: 'meta-vision', 
    canActivate: [LoggedGuard],
    component: MetavisionComponent,
  },
  {
    path: 'atualizar-meta-vision', 
    canActivate: [LoggedGuard],
    component: UpdateMetavisionComponent,
  },
  {
    path: 'minha-conta', 
    canActivate: [LoggedGuard],
    component: UserDataComponent,
  },
  {
    path: 'ativar-notificacoes',
    canActivate: [LoggedGuard],
    component: ActiveNotificationsComponent,
  },
  {
    path: 'habilitar-algotrading',
    canActivate: [LoggedGuard],
    component: ActiveAlgotradingComponent,
  },
  {
    path: 'perguntas-frequentes',
    canActivate: [LoggedGuard],
    component: FaqComponent,
  },
  {
    path: 'desempenho/:id',
    canActivate: [LoggedGuard],
    component: PerformanceComponent,
  },
  {
    path: 'ativar-conta',
    canActivate: [LoggedGuard],
    component: EnabledAccountComponent,
  },
  {
    path: 'ativar-conta-manual',
    canActivate: [LoggedGuard],
    component: EnabledAccountManualComponent,
  },
  {
    path: 'pagamento/:id',
    component: PaymentComponent,
  },
  {
    path: 'cobranca/pix',
    component: PixComponent,
  },
  {
    path: 'cobranca/boleto',
    component: BoletoComponent,
  },
  {
    path: 'erro-pagamento',
    component: PaymentMessageComponent,
  },
  {
    path: 'pagamento-confirmado',
    component: PaymentMessageComponent,
  },
  {
    path: 'meus-cartoes',
    canActivate: [LoggedGuard],
    component: CreditCardComponent,
  },
  {
    path: 'minhas-transacoes',
    canActivate: [LoggedGuard],
    component: HistoryTransactionsComponent,
  },
  {
    path: 'regras-objetivos',
    canActivate: [LoggedGuard],
    component: RulesObjectivesComponent,
  },
  {
    path: 'ativos-permitidos',
    canActivate: [LoggedGuard],
    component: AssetsAllowedComponent,
  },
  {
    path: 'importar-operacoes/:id',
    canActivate: [LoggedGuard],
    component: ImportComponent,
  },
  {
    path: 'not-found', 
    canActivate: [LoggedGuard],
    component: NotFoundComponent,
  },
  {
    path: 'manutencao', 
    canActivate: [MaintenanceGuard],
    component: MaintenanceComponent,
  },
  {
    path: 'cadastro', 
    component: UserRegisterComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'not-found',
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class AppRoutingModule { }
