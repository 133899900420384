<app-auth-header page="assets-allowed"></app-auth-header>

<div class="content">
    <div class="title">
        Ativos permitidos para negociações
    </div>
    <section class="row-content">
        <div class="card-filter">
            <div class="card">
                <span class="desc">Selecione a conta de negociação para visualizar os ativos e quantidade de contratos permitidos para cada um deles</span>
                <span class="desc desc-alert"><span class="color-danger">* </span>Em caso de operações realizadas em ativos fora da lista ou quantidade de contratos acima do permitido, haverá encerramento da operação e <strong>PERCA</strong> de capital referente ao spread ou variação do mercado no momento do encerramento</span>
                <div class=" filter">
                    <div class="select">
                        <mat-form-field>
                            <mat-select placeholder="Conta de negociação" #select>
                                <mat-option *ngFor="let account of listAccounts" [value]="account">
                                    <span>{{account.account}} - </span>
                                    <span *ngIf="account.status == 2 && account.activated">Em Teste</span>
                                    <span *ngIf="account.status == 4">Aprovado</span>
                                    <span *ngIf="account.status == 2 && !account.activated">Aguardando ativação</span>
                                    <span *ngIf="(account.status > 2 && account.status != 4) && account.test_account_operation.status == 3">Reprovada</span>
                                    <span *ngIf="(account.status > 2 && account.status != 4) && account.test_account_operation.status != 3">Desativada</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <button class="button-primary" (click)="seach(select.value)">
                        <span>Buscar</span>
                    </button>
                </div>
            </div>
        </div>
    </section>

    <div class="plan_title" *ngIf="account_operation">
        <div class="title">
            {{account_operation.plan.title}}
        </div>
        <div class="subtitle">
            <span>Saldo inicial da conta: </span>
            <span class="value">{{account_operation.plan.value_account | currency: '$'}}</span>
        </div>
    </div>
    <section class="row-content table-history" *ngIf="account_operation">
        <table>
            <thead>
                <tr>
                    <th>Conta de negociação</th>
                    <th>Tipo</th>
                    <th>Nome do Arquivo</th>
                    <th>Data</th>
                    <th>Status</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of listImport">
                    <td>{{item.account_operation}}</td>
                    <td>{{item.created_at | date: 'dd/MM/yyyy'}}</td>
                    <td>{{item.name}}</td>
                    <td>
                        <div [ngClass]="{'color-primary-medium': item.status == 'Concluído', 
                                         'color-warning': item.status == 'Processando' || item.status == 'Em fila',
                                         'color-danger': item.status == 'Cancelada' || item.status == 'Falha'}">
                            {{item.status}}
                        </div>
                    </td>
                    <td class="action">
                        <span *ngIf="item.status != 'Processando' && diffDates(item.created_at) < 3" (click)="delete(item.id)">
                            <img src="assets/icon/trash.svg" />
                        </span>
                    </td>
                    <td class="action">
                        <a href="{{item.file}}" target="_blank" (click)="downloadFile(item)">
                            <img src="assets/icon/download.svg" />
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</div>