import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountOperationModel } from 'src/app/models/account-operation.model';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {
  account: AccountOperationModel;

  constructor(private modalService: ModalService, private _snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.account = this.modalService.getContent() as AccountOperationModel;
    this.modalService.setContent(null);
  }

  closeModal() {
    this.modalService.closeModal();
  }

  copyText(text_to_copy: string, message: string) {
    navigator.clipboard.writeText(text_to_copy).then(() => {
      this._snackBar.open(message, '',  {duration: 2000})
    }).catch(err => { });
  }
}
