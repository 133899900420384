<app-simple-header page="payment"></app-simple-header>

<div class="content" *ngIf="!loading">

    <div class="card">
        <div class="card-header">
            <div class="logo"><img src="assets/images/insider_logo.png" /></div>
            <div class="description">
                <span class="title">Insider Investe - Mesa propritária</span>
                <span class="author">Autor: PRIME INVESTE</span>
                <span class="value" *ngIf="plan">{{plan.value_plan | currency: 'R$'}}</span>
                <span class="plan" *ngIf="plan">PLANO {{plan.title}}</span>
            </div>
        </div>

        <div class="buy-detail">
            <span class="detail">Detalhes da compra</span>
            <div class="buy">
                <div class="">
                    <span class="title">Insider Investe - Mesa proprietária</span>
                    <span class="title selected-platform" *ngIf="plan && plan.value_black_arrow">
                        + Plataforma
                        <span *ngIf="payment.platform == 'MT5'">Meta trader 5 - {{metaTraderValue | currency: 'R$'}}</span>
                        <span *ngIf="payment.platform == 'BA'">Black Arrow - {{blackArrowValue | currency: 'R$'}}</span>
                    </span>
                </div>
                <span class="price" *ngIf="plan">{{plan.value_plan | currency: 'R$'}}</span>
            </div>
        </div>

        <div class="payment-code">
            <div class="warning">
                <p>Para ativar sua conta de negociação, escaneie o código de barras no aplicativo do seu banco ou de pagamento Pix, realize o pagamento e em até 30 minutos sua conta estará ativada automaticamente.</p>
            </div>
            
            <div class="pix" *ngIf="pay_pix">
                <img src="data:image/jpeg;base64, {{pay_pix.encodedImage}}"/>
                <span>ou</span>
                <button class="button-secondary" [cdkCopyToClipboard]="pay_pix.payload" *ngIf="pay_pix" (click)="copyPix()">Copiar código PIX</button>
            </div>
        </div>
    </div>
</div>