<app-auth-header page="meta-vision"></app-auth-header>

<div class="content" *ngIf="configuration">
    <div >
        <section >
            <div class="title">Ativação da conta e instalação do Meta Vision</div>
            <div class="over-text">Olá <span class="color-highlight">{{user.name.split(' ')[0]}}</span>, assista o vídeo a seguir e siga o passo a passo para realizar a ativação da sua conta. Após a ativação a sua conta de negociação estará pronta para iniciar o teste.</div>

            <div class="tutorial">
                <iframe class="video" [src]="configuration.url_tutorial_meta_vision"  width="640" height="360" frameborder="0" title="Video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen *ngIf="this.configuration.url_tutorial_meta_vision"></iframe>
    
                <button class="button-primary" (click)="download_file()"><i class="fa-solid fa-download"></i>Baixar Meta Vision</button>
                <button class="button-outline" *ngIf="configuration.url_tutorial_meta_vision_manual" [routerLink]="'/ativar-conta-manual'">Instalar manualmente</button>
            </div>
        </section>
       
       
    </div>
    
</div>