import { Component } from '@angular/core';
import { AccountOperationCommand } from 'src/app/commands/account-operation.command';
import { AccountOperationModel } from 'src/app/models/account-operation.model';
import { ModalService } from 'src/app/services/utils/modal.service';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-warning-active',
  templateUrl: './warning-active.component.html',
  styleUrls: ['./warning-active.component.scss']
})
export class WarningActiveComponent {
  url: string = '';
  account: AccountOperationModel;

  constructor(
    private modalService: ModalService, 
    private accountOperationCommand: AccountOperationCommand,
    private alertComponent: AlertService,
  ) { }

  ngOnInit(): void {
    const {url, account} = this.modalService.getContent() as any;
    this.url = url;
    this.account = account;
    this.modalService.setContent(null);
  }

  closeModal() {
    this.modalService.closeModal();
  }

  accept() {
    this.accountOperationCommand.acceptActive(this.account.id).then((result: boolean) => {
      if(result){
        window.open(this.url, '_blank');
        this.closeModal();
      }else{
        this.alertComponent.presentAlert("error", "Erro no aceite", "Não foi possível aceitar os termos.");
      }
    });
  }
}
