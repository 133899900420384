<app-simple-header page="payment"></app-simple-header>

<div class="content" *ngIf="!loading">

    <div class="card">
        <div class="card-header">
            <div class="logo"><img src="assets/images/insider_logo.png" /></div>
            <div class="description">
                <span class="title">Insider Investe - Mesa propritária</span>
                <span class="author">Autor: PRIME INVESTE</span>
                <span class="value" *ngIf="plan">{{plan.value_plan | currency: 'R$'}}</span>
                <span class="plan" *ngIf="plan">PLANO {{plan.title}}</span>
            </div>
        </div>

        <form [formGroup]="formValidation">
            <div class="input input-space-min">
                <input class="input-box" type="text" placeholder="Informe seu nome completo"
                    [class.input-box-error]="submitted && errorCtr.name.errors?.required" formControlName="name"
                    (focus)="focus('focus', 'name')" (blur)="focus('unfocus', 'name')">
                <span class="error-form" *ngIf="submitted && errorCtr.name.errors?.required">
                    O campo nome é obrigatório
                </span>
            </div>
            <div class="input input-space-min">
                <input class="input-box" type="email" placeholder="Informe seu email" 
                [class.input-box-error]="submitted && errorCtr.email.errors?.required" formControlName="email"
                    (focus)="focus('focus', 'email')" (blur)="focus('unfocus', 'email')">
                <span class="error-form" *ngIf="submitted && errorCtr.email.errors?.required">
                    O campo email é obrigatório
                </span>
            </div>
            <div class="input input-space-min">
                <input class="input-box" type="email" placeholder="Confirme seu email" 
                [class.input-box-error]="submitted && errorCtr.email_confirm.errors?.required" formControlName="email_confirm"
                    (focus)="focus('focus', 'email_confirm')" (blur)="focus('unfocus', 'email_confirm')">
                <span class="error-form" *ngIf="submitted && errorCtr.email_confirm.errors?.required">
                    O campo confirmação de email é obrigatório
                </span>
            </div>
            <div class="input input-space-min">
                <input class="input-box" type="text" placeholder="CPF" 
                [class.input-box-error]="submitted && errorCtr.document.errors?.required" formControlName="document"
                    (focus)="focus('focus', 'document')" (blur)="focus('unfocus', 'document')"
                    mask="000.000.000-00||00.000.000/0000-00">
                <span class="error-form" *ngIf="submitted && errorCtr.document.errors?.required">
                    O campo confirmação de email é obrigatório
                </span>
            </div>
            <div class="input">
                <input class="input-box" type="text" placeholder="Informe seu telefone"
                    [class.input-box-error]="submitted && errorCtr.phone.errors?.required" formControlName="phone"
                    (focus)="focus('focus', 'phone')" (blur)="focus('unfocus', 'phone')"
                    mask="(00) 0000-0000||(00) 0 0000-0000">
                <span class="error-form" *ngIf="submitted && errorCtr.phone.errors?.required">
                    O campo telefone é obrigatório
                </span>
            </div>

            <div class="payment-type">
                <div class="card-payment" [class.active]="pay_method == 'CARTAO'" (click)="changePayment('CARTAO')">
                    <img src="assets/icons/credit.svg"/>
                    <span>Cartão de crédito</span>
                </div>
                <div class="card-payment" [class.active]="pay_method == 'PIX'" (click)="changePayment('PIX')">
                    <img src="assets/icons/pix_logo.svg"/>
                    <span>Pix</span>
                </div>
                <div class="card-payment" [class.active]="pay_method == 'BOLETO'" (click)="changePayment('BOLETO')">
                    <img src="assets/icons/boleto.svg"/>
                    <span>Boleto</span>
                </div>
                <div class="card-payment" [class.active]="pay_method == 'GLOBAL'" (click)="changePayment('GLOBAL')">
                    <img src="assets/icons/global.svg"/>
                    <span>Pagamento do exterior</span>
                </div>
            </div>

            <div class="payment-credit" *ngIf="pay_method == 'CARTAO'">
                <span class="detail">Dados do cartão</span>
                <div class="input input-space-min">
                    <input class="input-box" type="text" placeholder="Informe o numero do cartão" 
                    [class.input-box-error]="submitted && errorCtr.card_number.errors?.required" formControlName="card_number"
                        (focus)="focus('focus', 'card_number')" (blur)="focus('unfocus', 'card_number')"
                        mask="0000 0000 0000 0000">
                    <span class="error-form" *ngIf="submitted && errorCtr.card_number.errors?.required">
                        O campo numero do cartão é obrigatório
                    </span>
                </div>
                <div class="input input-space-min">
                    <input class="input-box" type="text" placeholder="Informe o nome do titular"
                        [class.input-box-error]="submitted && errorCtr.card_name.errors?.required" formControlName="card_name"
                        (focus)="focus('focus', 'card_name')" (blur)="focus('unfocus', 'card_name')">
                    <span class="error-form" *ngIf="submitted && errorCtr.card_name.errors?.required">
                        O campo nome do titular é obrigatório
                    </span>
                </div>
                <div class="card-back">
                    <div class="select">
                        <mat-form-field [class.input-box-error]="submitted && errorCtr.card_month.errors?.required">
                            <mat-select placeholder="Mês de vencimento" formControlName="card_month"
                                (focus)="focus('focus', 'card_month')" (blur)="focus('unfocus', 'card_month')">
                                <mat-option [value]="'01'">Janeiro</mat-option>
                                <mat-option [value]="'02'">Fevereiro</mat-option>
                                <mat-option [value]="'03'">Março</mat-option>
                                <mat-option [value]="'04'">Abril</mat-option>
                                <mat-option [value]="'05'">Maio</mat-option>
                                <mat-option [value]="'06'">Junho</mat-option>
                                <mat-option [value]="'07'">Julho</mat-option>
                                <mat-option [value]="'08'">Agosto</mat-option>
                                <mat-option [value]="'09'">Setembro</mat-option>
                                <mat-option [value]="'10'">Outubro</mat-option>
                                <mat-option [value]="'11'">Novembro</mat-option>
                                <mat-option [value]="'12'">Dezembro</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="error-form" *ngIf="submitted && errorCtr.card_month.errors?.required">
                            O campo mês é obrigatório
                        </span>
                    </div>
                    <div class="select">
                        <mat-form-field [class.input-box-error]="submitted && errorCtr.card_year.errors?.required">
                            <mat-select placeholder="Ano de vencimento" formControlName="card_year"
                                (focus)="focus('focus', 'card_year')" (blur)="focus('unfocus', 'card_year')">
                                <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="error-form" *ngIf="submitted && errorCtr.card_year.errors?.required">
                            O campo ano é obrigatório
                        </span>
                    </div>
                    <div class="input input-space-min">
                        <input class="input-box" type="text" placeholder="Informe o CVV"
                            [class.input-box-error]="submitted && errorCtr.card_cvv.errors?.required" formControlName="card_cvv"
                            (focus)="focus('focus', 'card_cvv')" (blur)="focus('unfocus', 'card_cvv')">
                        <span class="error-form" *ngIf="submitted && errorCtr.card_cvv.errors?.required"
                        mask="000">
                            O campo CVV é obrigatório
                        </span>
                    </div>
                </div>

                <span class="detail">Endereço do cartão</span>
                <div class="address">
                    <div class="input input-space-min">
                        <input class="input-box" type="text" placeholder="CEP" 
                        [class.input-box-error]="submitted && errorCtr.card_zipcode.errors?.required" formControlName="card_zipcode" #cep
                            (focus)="focus('focus', 'card_zipcode')" (blur)="focus('unfocus', 'card_zipcode')" (change)="getAddress(cep.value)" mask="00000-000">
                        <span class="error-form" *ngIf="submitted && errorCtr.card_zipcode.errors?.required">
                            O campo CEP é obrigatório
                        </span>
                    </div>
                    <div class="select input-space-min">
                        <mat-form-field [class.input-box-error]="submitted && errorCtr.card_state.errors?.required">
                            <mat-select placeholder="Estado" formControlName="card_state"
                                (focus)="focus('focus', 'card_state')" (blur)="focus('unfocus', 'card_state')">
                                <mat-option [value]="'AC'">Acre</mat-option>
                                <mat-option [value]="'AL'">Alagoas</mat-option>
                                <mat-option [value]="'AP'">Amapá</mat-option>
                                <mat-option [value]="'AM'">Amazonas</mat-option>
                                <mat-option [value]="'BA'">Bahia</mat-option>
                                <mat-option [value]="'CE'">Ceará</mat-option>
                                <mat-option [value]="'DF'">Distrito Federal</mat-option>
                                <mat-option [value]="'ES'">Espírito Santo</mat-option>
                                <mat-option [value]="'GO'">Goiás</mat-option>
                                <mat-option [value]="'MA'">Maranhão</mat-option>
                                <mat-option [value]="'MT'">Mato Grosso</mat-option>
                                <mat-option [value]="'MS'">Mato Grosso do Sul</mat-option>
                                <mat-option [value]="'MG'">Minas Gerais</mat-option>
                                <mat-option [value]="'PA'">Pará</mat-option>
                                <mat-option [value]="'PB'">Paraíba</mat-option>
                                <mat-option [value]="'PR'">Paraná</mat-option>
                                <mat-option [value]="'PE'">Pernambuco</mat-option>
                                <mat-option [value]="'PI'">Piauí</mat-option>
                                <mat-option [value]="'RJ'">Rio de Janeiro</mat-option>
                                <mat-option [value]="'RN'">Rio Grande do Norte</mat-option>
                                <mat-option [value]="'RS'">Rio Grande do Sul</mat-option>
                                <mat-option [value]="'RO'">Rondônia</mat-option>
                                <mat-option [value]="'RR'">Roraima</mat-option>
                                <mat-option [value]="'SC'">Santa Catarina</mat-option>
                                <mat-option [value]="'SP'">São Paulo</mat-option>
                                <mat-option [value]="'SE'">Sergipe</mat-option>
                                <mat-option [value]="'TO'">Tocantins</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="error-form" *ngIf="submitted && errorCtr.card_state.errors?.required">
                            O campo Estado é obrigatório
                        </span>
                    </div>
                    <div class="input input-space-min">
                        <input class="input-box" type="text" placeholder="Cidade" 
                        [class.input-box-error]="submitted && errorCtr.card_city.errors?.required" formControlName="card_city"
                            (focus)="focus('focus', 'card_city')" (blur)="focus('unfocus', 'card_city')">
                        <span class="error-form" *ngIf="submitted && errorCtr.card_city.errors?.required">
                            O campo Cidade é obrigatório
                        </span>
                    </div>
                    <div class="input input-space-min">
                        <input class="input-box" type="text" placeholder="Informe o endereço"
                            [class.input-box-error]="submitted && errorCtr.card_address.errors?.required" formControlName="card_address"
                            (focus)="focus('focus', 'card_address')" (blur)="focus('unfocus', 'card_address')">
                        <span class="error-form" *ngIf="submitted && errorCtr.card_address.errors?.required">
                            O campo endereço é obrigatório
                        </span>
                    </div>
                    <div class="input input-space-min">
                        <input class="input-box" type="text" placeholder="Informe o complemento"
                            [class.input-box-error]="submitted && errorCtr.card_complement.errors?.required" formControlName="card_complement"
                            (focus)="focus('focus', 'card_complement')" (blur)="focus('unfocus', 'card_complement')">
                        <span class="error-form" *ngIf="submitted && errorCtr.card_complement.errors?.required">
                            O campo de complemento é obrigatório
                        </span>
                    </div>
                    <div class="input input-space-min">
                        <input class="input-box" type="text" placeholder="Informe o bairro"
                            [class.input-box-error]="submitted && errorCtr.card_district.errors?.required" formControlName="card_district"
                            (focus)="focus('focus', 'card_district')" (blur)="focus('unfocus', 'card_district')">
                        <span class="error-form" *ngIf="submitted && errorCtr.card_district.errors?.required">
                            O campo de Bairro é obrigatório
                        </span>
                    </div>
                    <div class="input">
                        <input class="input-box" type="text" placeholder="Nº"
                            [class.input-box-error]="submitted && errorCtr.card_address_number.errors?.required" formControlName="card_address_number"
                            (focus)="focus('focus', 'card_address_number')" (blur)="focus('unfocus', 'card_address_number')"
                            mask="0||00||000||0000">
                        <span class="error-form" *ngIf="submitted && errorCtr.card_address_number.errors?.required">
                            O campo número é obrigatório
                        </span>
                    </div>
                </div>
            </div>

            <div class="payment-code" *ngIf="pay_method == 'PIX' || pay_method == 'BOLETO'">
                <div class="warning" *ngIf="pay_method == 'PIX'">
                    <p>Ao prosseguir com a ativação da sua conta de negociação, você receberá um código de barras e um código Pix exclusivos. O código de barras pode ser escaneado usando qualquer aplicativo de pagamento Pix, enquanto o código Pix pode ser copiado e colado no seu aplicativo bancário.</p>
                    <p>Por favor, observe que o código gerado tem uma validade de 15 minutos. Realize o pagamento dentro deste período para evitar inconvenientes.</p>
                    <p>Após a confirmação do seu pagamento, sua conta de negociação será ativada dentro de até 30 minutos. Após a ativação, enviaremos uma confirmação por e-mail.</p>
                    <p>Estamos aqui para ajudar em cada etapa do processo. Se tiver alguma dúvida ou precisar de assistência adicional, entre em contato conosco através do e-mail suporte@insiderinveste.com.br.</p>
                </div>
                <div class="warning" *ngIf="pay_method == 'BOLETO'">
                    <p>Ao prosseguir com a ativação da sua conta de negociação, um boleto será gerado automaticamente com vencimento para daqui a 5 dias.</p>
                    <p>Após a ativação, enviaremos uma confirmação por e-mail.</p>
                    <p>Estamos aqui para ajudar em cada etapa do processo. Se tiver alguma dúvida ou precisar de assistência adicional, entre em contato conosco.</p>
                </div>
            </div>
        </form>
        <div class="payment-credit" *ngIf="pay_method == 'GLOBAL'">
            <span class="detail">Pagamentos do exterior</span>
            <p>Para pagamentos realizados no exterior, entre em contato com o nosso suporte através do e-mail suporte@insiderinveste.com.br, que disponibilizaremos um link exclusivo para realizar o pagamento em outra moeda.</p>
        </div>

        <div class="platforms" *ngIf="plan && plan.value_black_arrow && pay_method != 'GLOBAL'">
            <span class="detail">Selecione a plataforma de negociação:</span>
            <div class="card-platform" [class.active]="platform_selected == 'MT5'" (click)="changePlatform('MT5')">
                <div class="logo"><img src="assets/icons/metatrader.svg" /></div>
                <div class="description">
                    <span class="title">Meta trader 5</span>
                    <span class="value">{{metaTraderValue | currency: 'R$'}}</span>
                    <span class="obs"><span class="color-danger">*</span> Disponibilizado imediatamente após a confirmação do pagamento</span>
                </div>
            </div>
            <div class="card-platform" [class.active]="platform_selected == 'BA'" (click)="changePlatform('BA')">
                <div class="logo"><img src="assets/icons/blackarrow.svg" /></div>
                <div class="description">
                    <span class="title">Black Arrow</span>
                    <span class="value">{{blackArrowValue | currency: 'R$'}}</span>
                    <span class="obs"><span class="color-danger">*</span> Disponibilizado em até 2 dias úteis após a confirmação do pagamento</span>
                </div>
            </div>
        </div>

        <div class="buy-detail" *ngIf="pay_method != 'GLOBAL'">
            <span class="detail">Detalhes da compra</span>
            <div class="buy">
                <div class="">
                    <span class="title">Insider Investe - Mesa proprietária</span>
                    <span class="title selected-platform" *ngIf="plan && plan.value_black_arrow">
                        + Plataforma
                        <span *ngIf="platform_selected == 'MT5'">Meta trader 5 - {{metaTraderValue | currency: 'R$'}}</span>
                        <span *ngIf="platform_selected == 'BA'">Black Arrow - {{blackArrowValue | currency: 'R$'}}</span>
                    </span>
                </div>
                <span class="price" *ngIf="plan">{{getFinalValue(plan.value_plan) | currency: 'R$'}}</span>
            </div>
        </div>
        <div class="button-center" *ngIf="pay_method != 'GLOBAL'">
            <button class="button-primary" (click)="submit()">Comprar agora</button>
        </div>
    </div>
</div>