<div class="card">
    <section class="card-header">
        <div class="first-item">Regra</div>
        <div>Objetivo</div>
        <div>Resultados</div>
        <div>Status</div>
    </section>
    <mat-accordion class="mat-accordion">
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(0)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[0]" src="assets/icons/show-more.svg" alt=""> 
                            <img *ngIf="expanded[0]" src="assets/icons/show-less.svg" alt=""> 
                            <span>Meta de lucro</span>
                        </div>
                        <div class="rule-header-result-goal">$ {{account_operation.plan.profit_target | number:'1.2-2' }}</div>
                        <div class="rule-header-result-value">$ {{performance.saldo_liquido | number:'1.2-2' }}</div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 1"><img src="assets/icons/warning.svg" alt=""><span>Em teste</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 2"><img src="assets/icons/approved.svg" alt=""><span>Aprovado</span></div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                A sua meta de lucro é de $ {{account_operation.plan.profit_target | number:'1.2-2' }}. Você recebeu uma conta de $ {{account_operation.plan.value_account | number:'1.2-2' }}, para que você possa ser aprovado a sua conta deverá alcançar o valor de $ {{ value_meta | number:'1.2-2' }}.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(1)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[1]" src="assets/icons/show-more.svg" alt=""> 
                            <img *ngIf="expanded[1]" src="assets/icons/show-less.svg" alt=""> 
                            <span>Perda máxima</span>
                        </div>
                        <div class="rule-header-result-goal">-$ {{account_operation.plan.trailing_drawdown | number:'1.2-2' }}</div>
                        <div class="rule-header-result-value">-$ {{ getResultLossAccount() | number:'1.2-2' }}</div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 1"><img src="assets/icons/warning.svg" alt=""><span>Em teste</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 2"><img src="assets/icons/approved.svg" alt=""><span>Aprovado</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 3 && account_operation.test_account_operation.status_maximum_loss == 3"><img src="assets/icons/repproved.svg" alt=""><span>Reprovado</span></div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                A perda máxima na sua conta é de $ {{account_operation.plan.trailing_drawdown | number:'1.2-2' }}, é importante manter atenção na sua perda máxima porque sempre que você alcançar valores mais altos, o limite mínimo da sua conta também será atualizado para que o valor da perda de ${{account_operation.plan.trailing_drawdown | number:'1.2-2' }} seja mantido.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle *ngIf="account_operation.plan.limit_loss_day">
            <mat-expansion-panel-header (click)="updateExpanded(2)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[2]" src="assets/icons/show-more.svg" alt=""> 
                            <img *ngIf="expanded[2]" src="assets/icons/show-less.svg" alt=""> 
                            <span>Perda máxima diária</span>
                        </div>
                        <div class="rule-header-result-goal">-$ {{account_operation.plan.limit_loss_day | number:'1.2-2' }}</div>
                        <div class="rule-header-result-value">-$ {{(profit_today < 0 ? profit_today : 0)  | number:'1.2-2' }}</div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 1"><img src="assets/icons/warning.svg" alt=""><span>Em teste</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 2"><img src="assets/icons/approved.svg" alt=""><span>Aprovado</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 3 && account_operation.test_account_operation.status_maximum_loss_day == 3"><img src="assets/icons/repproved.svg" alt=""><span>Reprovado</span></div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                A perda máxima diária na sua conta é de -$ {{account_operation.plan.limit_loss_day | number:'1.2-2' }}, Ou seja, durante o seu dia de operação você não poderá atingir o limite de perdas diária, mesmo que no próximo trade você consiga recuperar o valor, a perda máxima sendo alcançada o teste será finalizado. 
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(3)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[3]" src="assets/icons/show-more.svg" alt=""> 
                            <img *ngIf="expanded[3]" src="assets/icons/show-less.svg" alt=""> 
                            <span>Mínimo de dias operados</span>
                        </div>
                        <div class="rule-header-result-goal">{{account_operation.plan.minimum_days_operation }}</div>
                        <div class="rule-header-result-value">{{dashboard.minimum_days_operation}}</div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 1"><img src="assets/icons/warning.svg" alt=""><span>Em teste</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 2"><img src="assets/icons/approved.svg" alt=""><span>Aprovado</span></div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                Para ser um trader INSIDER você precisa ter pelo menos {{account_operation.plan.minimum_days_operation }} operados. A aprovação só é realizada após a meta ser alcançada ou mantida após os {{account_operation.plan.minimum_days_operation }} operados.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(4)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[4]" src="assets/icons/show-more.svg" alt=""> 
                            <img *ngIf="expanded[4]" src="assets/icons/show-less.svg" alt=""> 
                            <span>Não ultrapassar {{account_operation.plan.limit_days_not_operation}} dias sem operar</span>
                        </div>
                        <div class="rule-header-result-goal">{{account_operation.plan.limit_days_not_operation}}</div>
                        <div class="rule-header-result-value">{{dashboard.maximum_days_not_operation}}</div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 1"><img src="assets/icons/warning.svg" alt=""><span>Em teste</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 2"><img src="assets/icons/approved.svg" alt=""><span>Aprovado</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 3 && account_operation.test_account_operation.status_maximum_days_not_operated == 3"><img src="assets/icons/repproved.svg" alt=""><span>Reprovado</span></div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                Para se tornar um trader INSIDER você não poderá ficar mais que {{account_operation.plan.limit_days_not_operation}} sem operar.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(5)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[5]" src="assets/icons/show-more.svg" alt=""> 
                            <img *ngIf="expanded[5]" src="assets/icons/show-less.svg" alt=""> 
                            <span>Não realizar swing trade</span>
                        </div>
                        <div class="rule-header-result-goal">Não realizar</div>
                        <div class="rule-header-result-value">Não realizou</div>
                        
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 1"><img src="assets/icons/warning.svg" alt=""><span>Em teste</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 2"><img src="assets/icons/approved.svg" alt=""><span>Aprovado</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 3 && account_operation.test_account_operation.status_do_not_swing_trade == 3"><img src="assets/icons/repproved.svg" alt=""><span>Reprovado</span></div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                Não é permitido realizar operações de Swing Trade, ou seja, operações que são fechadas no dia seguinte após o dia que ela foi aberta. É importante se atentar para a abertura e fechamento do mercado que você está operando.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(6)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[6]" src="assets/icons/show-more.svg" alt=""> 
                            <img *ngIf="expanded[6]" src="assets/icons/show-less.svg" alt=""> 
                            <span>Consistência</span>
                        </div>
                        <div class="rule-header-result-goal">80%</div>
                        <div class="rule-header-result-value {{consistency.color}}">{{consistency.percent | number:'1.2-2' }}%</div>
                        
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 1"><img src="assets/icons/warning.svg" alt=""><span>Em teste</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 2"><img src="assets/icons/approved.svg" alt=""><span>Aprovado</span></div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                Para que você possa ser aprovado o seu nível de consistencia precisa ser igual ou estar acima de 80%. O nível de consistência é calculado da seguinte forma, pegamos os seus 4 melhores trades, obtemos a média dos resultados entre o segundo, terceiro e quarto melhor. A média obtida precisa ser pelo menos 80% do seu melhor trading.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(7)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[7]" src="assets/icons/show-more.svg" alt=""> 
                            <img *ngIf="expanded[7]" src="assets/icons/show-less.svg" alt=""> 
                            <span>Consistência Aprovado</span>
                        </div>
                        <div class="rule-header-result-goal">Clique para visualizar</div>
                        <div class="rule-header-result-value"></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 1"><img src="assets/icons/warning.svg" alt=""><span>Em teste</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 2"><img src="assets/icons/approved.svg" alt=""><span>Aprovado</span></div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                Após aprovado deve ser capaz de demonstrar que continua operando com consistência. Caso seja aprovado antes do limite mínimo de dias para aprovação, os próximos Trades devem ser feitos com lotes pelo menos 60% da média dos lotes, e a duração das operações deve ser pelo menos 50% da média da duração das operações anteriores.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle *ngIf="account_operation.platform_type == 1">
            <mat-expansion-panel-header (click)="updateExpanded(8)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[8]" src="assets/icons/show-more.svg" alt=""> 
                            <img *ngIf="expanded[8]" src="assets/icons/show-less.svg" alt=""> 
                            <span>Meta Vision ativado</span>
                        </div>
                        <div class="rule-header-result-goal">Deve estar ativado em todas operações</div>
                        <div class="rule-header-result-value"></div>
                        
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 1"><img src="assets/icons/warning.svg" alt=""><span>Em teste</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 2"><img src="assets/icons/approved.svg" alt=""><span>Aprovado</span></div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                O Meta Vision é responsável por enviar as suas operações para o nosso servidor em tempo real. Para que o seu teste seja válido é <strong>essencial</strong> que ele esteja devidamente configurado na sua conta de operações e esteja sempre ativado no Meta Trader 5 durante o periodo que suas operações forem realizadas. <a [routerLink]="'/meta-vision'">Clique aqui</a> saber como configurar o Meta Vision.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(9)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[9]" src="assets/icons/show-more.svg" alt=""> 
                            <img *ngIf="expanded[9]" src="assets/icons/show-less.svg" alt=""> 
                            <span>Operar somente ativos permitidos</span>
                        </div>
                        <div class="rule-header-result-goal">Clique aqui para visualizar</div>
                        <div class="rule-header-result-value"></div>
                        
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 1"><img src="assets/icons/warning.svg" alt=""><span>Em teste</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 2"><img src="assets/icons/approved.svg" alt=""><span>Aprovado</span></div>
                        <div class="rule-header-result-result" *ngIf="account_operation.test_account_operation.status == 3 && account_operation.test_account_operation.status_do_not_swing_trade == 3"><img src="assets/icons/repproved.svg" alt=""><span>Reprovado</span></div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                <div>
                    Para se tornar um trader INSIDER é preciso realizar operações somente através dos ativos e quantidade de contratos permitidos, 
                    qualquer outro ativo operado que não esteja na lista poderá resultar em reprovação. Clique no botão abaixo e consulte a lista de ativos para o seu plano.
                </div>
                <div class="assets">
                    <button class="button-primary" [routerLink]="'/ativos-permitidos'" [state]="{account: account_operation.account}">Visualizar ativos permitidos</button>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>