<section>
    <div class="subtitle">O ícone azul do MetaVisionRealTime não está ficando azul, o que fazer?</div>
    <div class="over-text">Quando o ícone não fica azul, o motivo é que o Algotrading está desabilitado.
    </div>

    <div class="subtitle margin-top">SOLUÇÃO</div>
    <div class="over-text">Siga os passos a seguir para resolver o problema:</div>
    <br>
    <div class="over-text"><strong>Passo 1 </strong>- Habilitar o Algotrading conforme a imagem a seguir</div>
    <div class="inline">
        <a href="assets/images/faq/algotrading/algotrading1.png" target="_blank">
            <img class="step1" src="assets/images/faq/algotrading/algotrading1.png" alt="algotrading1">
        </a>
    </div>
    <br>
    <div class="over-text"><strong>Passo 2 </strong>- Arraste o MetavisionRealTime para o gráfico novamente</div>
    <div class="over-text"><strong>Passo 3 </strong>- Deixe as configurações do MetavisionRealTime conforme as imagens a seguir. Com os campos dll e algotrading marcados</div>
    <div class="inline">
        <a href="assets/images/faq/algotrading/algotrading2.png" target="_blank">
            <img class="step2" src="assets/images/faq/algotrading/algotrading2.png" alt="algotrading2">
        </a>
    </div>
    <div class="inline">
        <a href="assets/images/faq/algotrading/algotrading3.png" target="_blank">
            <img class="step3" src="assets/images/faq/algotrading/algotrading3.png" alt="algotrading3">
        </a>
    </div>
</section>