import { Component, Inject, OnInit } from '@angular/core';
import { UserCommand } from 'src/app/commands/user.command';
import { ResetInfoComponent } from 'src/app/components/reset-info/reset-info.component';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { ModalService } from 'src/app/services/utils/modal.service';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { AlertService } from 'src/app/components/alert/alert.service';
import { PlanCommand } from 'src/app/commands/plan.command';
import { PlanModel } from 'src/app/models/plan.model';
import { AccountOperationCommand } from 'src/app/commands/account-operation.command';
import { AccountOperationModel } from 'src/app/models/account-operation.model';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AccountInfoComponent } from 'src/app/components/account-info/account-info.component';
import { WarningActiveComponent } from 'src/app/components/warning-active/warning-active.component';
import { ConfigurationCommand } from 'src/app/commands/configuration.command';
import { ConfigurationModel } from 'src/app/models/configuration.model';
import { ModalVideoComponent } from 'src/app/components/modal-video/modal-video.component';
import { BlackArrowReleaseModel } from 'src/app/models/black-arrow-release.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  plans: PlanModel[] = [];
  atual_plan: PlanModel = null;
  list_account_operation: AccountOperationModel[];
  filter_accounts: AccountOperationModel[] = [];
  filter_blackarrow: BlackArrowReleaseModel[] = [];
  show_see_less: boolean = false;
  configuration: ConfigurationModel;
  typePlan: string = 'asset';

  constructor(
    private accountOperationCommand: AccountOperationCommand,
    private firebaseService: FirebaseService,
    private modalService: ModalService,
    private userCommand: UserCommand,
    private planCommand: PlanCommand,
    public pagesContentService: PagesContentService,
    private alertComponent: AlertService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private configurationCommand: ConfigurationCommand,
  ) { }

  ngOnInit(): void {
    this.firebaseService.viewScreen('home');

    this.getData();
    this.updateToken();
    this.getConfiguration();
  }

  getData() {

    if (localStorage.getItem('atual_plan')) {
      this.atual_plan = JSON.parse(localStorage.getItem('atual_plan')) as PlanModel;
    }

    this.planCommand.get().then((result: any) => {
      this.plans = result.results;
    })

    Promise.all([
      this.accountOperationCommand.get(),
      this.accountOperationCommand.blackArrowRelease(),
    ]).then(([accounts, releases]: [AccountOperationModel[], any]) => {
      if(releases && releases.results) {
        this.filter_blackarrow = releases.results as BlackArrowReleaseModel[];
      }
      if (accounts) {
        this.filter_accounts = accounts.filter(x => x.status == 2);
        this.list_account_operation = accounts;

        if ((!this.filter_accounts || this.filter_accounts.length == 0) 
        && (this.list_account_operation && this.list_account_operation.length > 0)) {
          this.filter_accounts = [this.list_account_operation[0]];
        }
      }
    });
  }

  getConfiguration() {
    this.configurationCommand.get().then((result: ConfigurationModel) => {
      this.configuration = result;
    });
  }

  reset() {
    let page_block = this.pagesContentService.getBlock('button-reset');

    if (page_block) {
      this.alertComponent.presentAlert("warning", "Conteúdo indisponível", page_block.message);
    } else {
      this.modalService.openModal(ResetInfoComponent, '800px', '400px')
    }
  }

  async updateToken() {
    if (Notification.permission == 'granted') {
      const token: string = await this.firebaseService.getToken() as string;

      if (token)
        this.userCommand.updateDeviceToken(token, false);
    }
  }

  showMore() {
    this.show_see_less = true;
    this.filter_accounts = this.list_account_operation;
  }

  seeLess() {
    this.show_see_less = false;
    this.filter_accounts = this.list_account_operation.filter(x => x.status == 2);

    if ((!this.filter_accounts || this.filter_accounts.length == 0) 
    && (this.list_account_operation && this.list_account_operation.length > 0)) {
      this.filter_accounts = [this.list_account_operation[0]];
    }
  }

  openInNewTab(route: string, account: AccountOperationModel) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    if(account.accept_value_retention || account.coupon_id)
      window.open(url, '_blank');
    else{
      this.modalService.setContent({url, account});
      this.modalService.openModal(WarningActiveComponent, '800px', '400px');
    }
  }

  openTutorial() {
    this.modalService.setContent({title: 'Bem vindo(a) ao Insider Invest', message: '', url: this.configuration.url_tutorial_platform});
    this.modalService.openModal(ModalVideoComponent, '700px');
  }

  viewCredentials(account: AccountOperationModel) {
    this.modalService.setContent(account);
    this.modalService.openModal(AccountInfoComponent, '800px', '400px');
  }

  hasStockPlans() {
    return this.plans.some(item => item.is_stock);
  }

  filterPlan() {
    if(this.typePlan == 'asset')
      return this.plans.filter(item => !item.is_stock);
    return this.plans.filter(item => item.is_stock);
  }
}
