<app-auth-header page="rules-objectives"></app-auth-header>

<div class="content">
    <div class="title">
        Regras e Objetivos
    </div>
    <section class="row-content">
        <div class="card-filter">
            <div class="card">
                <span class="desc">Selecione a conta de negociação para visualizar as regras e objetivos e clique em buscar</span>
                <div class=" filter">
                    <div class="select">
                        <mat-form-field>
                            <mat-select placeholder="Conta de negociação" [(ngModel)]="selectedAccount">
                                <mat-option *ngFor="let account of listAccounts" [value]="account.account">
                                    <span>{{account.account}} - </span>
                                    <span *ngIf="account.status == 2 && account.activated">Em Teste</span>
                                    <span *ngIf="account.status == 4">Aprovado</span>
                                    <span *ngIf="account.status == 2 && !account.activated">Aguardando ativação</span>
                                    <span *ngIf="(account.status > 2 && account.status != 4) && account.test_account_operation.status == 3">Reprovada</span>
                                    <span *ngIf="(account.status > 2 && account.status != 4) && account.test_account_operation.status != 3">Desativada</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <button class="button-primary" (click)="seach()">
                        <span>Buscar</span>
                    </button>
                </div>
            </div>
        </div>
    </section>

    <div class="plan_title" *ngIf="account_operation && dashboard && performance">
        <div class="title">
            {{account_operation.plan.title}}
        </div>
        <div class="subtitle">
            <span>Saldo inicial da conta: </span>
            <span class="value">{{account_operation.plan.value_account | currency: '$'}}</span>
        </div>
    </div>
    <section class="row-content" *ngIf="account_operation && dashboard && performance">
        <app-table-rules-objectives [account_operation]="account_operation" [dashboard]="dashboard" [performance]="performance"></app-table-rules-objectives>
    </section>
</div>