<app-auth-header page="home"></app-auth-header>

<div class="content">
    <div class="content-home">

        <div class="buttons-home">
            <button class="button-primary" (click)="reset()" *ngIf="pagesContentService.isVisible('button-reset')">
                Solicitar nova conta
            </button>

            <button class="button-secondary" (click)="openTutorial()" *ngIf="configuration && configuration.url_tutorial_platform">
                <img src="assets/icons/play.svg">Comece por aqui
            </button>
        </div>

        <div class="title-accounts">Contas INSIDER</div>

        <div class="list-accounts">
            <div class="card"  *ngFor="let account of filter_blackarrow">
                <div class="card-header">
                    <div class="account-info"><span>Sua conta será disponibilizada em até dois dias úteis após a confirmação do pagamento</span></div>
                    <div class="platform">
                        <img src="assets/icons/blackarrow.svg">
                        <div class="platform-name">Black Arrow</div>
                    </div>
                </div>
    
                <div class="section-info">
                    <div class="section-info-item">
                        <div class="section-info-item-description">Status:</div>
                        <div class="section-info-item-value color-warning" *ngIf="account.status == 2 && !account.account_operation">Aguardando ativação</div>
                    </div>
                </div>
            </div>
            <div class="card" *ngFor="let account of filter_accounts">
                <div class="card-header">
                    <div class="account-info">Número da conta: <span>{{account.account}}</span></div>
                    <div class="platform" *ngIf="account.platform_type == 1">
                        <img src="assets/icons/metatrader.svg">
                        <div class="platform-name">Meta trader 5</div>
                    </div>
                    <div class="platform" *ngIf="account.platform_type == 2">
                        <img src="assets/icons/blackarrow.svg">
                        <div class="platform-name">Black Arrow</div>
                    </div>
                </div>
                <div class="selo" *ngIf="account.type == 2">
                    Conta Real
                </div>
    
                <div class="section-info">
                    <div class="section-info-item">
                        <div class="section-info-item-description">Saldo:</div>
                        <div class="section-info-item-value">$ {{(account.account_balance ?? account.plan.value_account) | number:'1.2-2'}}</div>
                    </div>
                    <div class="section-info-item">
                        <div class="section-info-item-description">Status:</div>
                        <div class="section-info-item-value color-primary-medium" *ngIf="account.status == 2 && account.activated">Ativa</div>
                        <div class="section-info-item-value color-primary-medium" *ngIf="account.status == 4">Aprovado</div>
                        <div class="section-info-item-value color-warning" *ngIf="account.status == 2 && !account.activated">Aguardando ativação</div>
                        <div class="section-info-item-value color-danger" *ngIf="(account.status > 2 && account.status != 4) && account.test_account_operation.status == 3">Reprovada</div>
                        <div class="section-info-item-value color-danger" *ngIf="(account.status > 2 && account.status != 4) && account.test_account_operation.status != 3">Desativada</div>
                    </div>
                    <div class="section-info-item" *ngIf="account.activated && account.test_account_operation.status == 1 && account.type == 1 && (account.status <= 2) && account.platform_type == 1">
                        <div class="section-info-item-description">Resultado:</div>
                        <div class="section-info-item-value color-warning">Em teste</div>
                    </div>
                </div>
                <div class="section-buttons">
                    <app-button-icon-label icon="unsecurity" description="Credenciais" *ngIf="account.status == 2 && account.platform_type == 1" (click)="viewCredentials(account)"></app-button-icon-label>
                    <app-button-icon-label icon="graph" description="Desempenho" [routerLink]="'/desempenho/' + account.account" *ngIf="account.activated"></app-button-icon-label>
                    <app-button-icon-label icon="meta-vision" description="Clique aqui para ativar a conta" (click)="openInNewTab('/ativar-conta', account)" *ngIf="account.status == 2 && !account.activated && account.platform_type == 1"></app-button-icon-label>
                    <app-button-icon-label icon="rule" description="Regras e objetivos" [routerLink]="'/regras-objetivos'" [state]="{account: account.account}" *ngIf="account.activated"></app-button-icon-label>
                </div>
            </div>
        </div>
        <button class="button-view-more button-secondary"
            *ngIf="list_account_operation && list_account_operation.length > filter_accounts.length"
            (click)="showMore()">Ver mais</button>
        <button class="button-view-more button-secondary"
            *ngIf="show_see_less"
            (click)="seeLess()">Ver menos</button>

        <section class="section-plans">
            <div class="type-plans" *ngIf="hasStockPlans()">
                <button [ngClass]="{'button-primary': typePlan == 'asset', 'button-outline': typePlan != 'asset'}" (click)="typePlan = 'asset'">Índices / Derivativos</button>
                <button [ngClass]="{'button-primary': typePlan == 'stock', 'button-outline': typePlan != 'stock'}" (click)="typePlan = 'stock'">Ações americanas</button>
            </div>
            <div class="title-plans" *ngIf="hasStockPlans()">
                <span *ngIf="typePlan == 'asset'">Índices nacionais, internacionais e derivativos</span>
                <span *ngIf="typePlan == 'stock'">Ações Americanas</span>
            </div>
            <div class="color-over" *ngIf="plans == null">Nenhum plano encontrado</div>

            <div class="card-plans" *ngIf="plans && typePlan == 'asset'">
                <app-card-plan *ngFor="let plan of filterPlan()" [plan]="plan" [atual_plan]="atual_plan?.id"></app-card-plan>
            </div>
            <div class="card-plans" *ngIf="hasStockPlans() && typePlan == 'stock'">
                <app-card-plan *ngFor="let plan of filterPlan()" [plan]="plan" [atual_plan]="atual_plan?.id"></app-card-plan>
            </div>
        </section>
    </div>
</div>