<app-auth-header page="meta-vision"></app-auth-header>

<div class="content" *ngIf="configuration">
    <div [class.full-height]="step == 8">
        <mat-progress-bar class="progress-primary" mode="determinate" [value]="progressBar" *ngIf="step != 8"></mat-progress-bar>
        <section *ngIf="step == 1">
            <div class="title">Leia com atenção</div>
            <div class="over-text">Olá <span class="color-highlight">{{user.name.split(' ')[0]}}</span>, seja bem-vindo(a) ao
                Insider Investe, a sua plataforma de mesa proprietária. Siga os passos a seguir para habilitar a sua Conta
                de negociação. 
                <br><br>
                Após finalizar o passo a passo, acesse a tela inicial para verificar o status da sua conta.
                <br><br>
                E sempre que for operar lembre-se de acessar a tela de desempenho da sua conta de negociação para verificar o status do Meta Vision, ele
                precisa estar online. Qualquer dúvida, é só entrar em contato com a nossa equipe de suporte.</div>

            <section class="alert">
                <div class="alert-title">Informação importante sobre a instalação do Meta Vision</div>
                <div class="alert-description">O Meta Vision precisa ficar ativo em um gráfico, escolha um <strong>único gráfico</strong> para que ele fique configurado (recomendamos o gráfico do ativo EURUSD por não ter vencimento e estar sempre aberto), <strong>esse gráfico não poderá ser usado para operar</strong>, portanto, após a configuração do Meta Vision, deixe o gráfico <strong>minimizado ou em segundo plano</strong>, e abra novos gráficos para realizar suas operações</div>
            </section>
        </section>
        <section *ngIf="step == 2">
            <div class="title">Conta de negociação</div>
            <div class="over-text">O primeiro passo para configurar sua conta, é acessar a tela <a [routerLink]="'/home'" target="_blank" class="text-click">inicial</a> para visualizar a sua conta e senha do Meta Trader 5. Para visualizar suas credenciais de acesso siga os passos a seguir:*ngIf="account.activated"
            </div>
            <br>
            <div class="over-text"><strong>a) </strong>Acesse a tela inicial da plataforma e identifique a conta de negociação que você acabou de receber. Em seguida clique em <span class="color-highlight">Credenciais</span></div>
            
            <div class="inline img-center">
                <a href="assets/images/tutorial_install_metavision/platform1.png" target="_blank">
                    <img class="conta" src="assets/images/tutorial_install_metavision/platform1.png" alt="Conta de negociação">
                </a>
            </div>
            <br>
            <div class="over-text"><strong>b) </strong>Será aberto um pop-up com as informações necessárias para que você possa se autenticar no MetaTrader 5.</div>
            
            <div class="inline img-center">
                <a href="assets/images/tutorial_install_metavision/platform2.png" target="_blank">
                    <img class="conta" src="assets/images/tutorial_install_metavision/platform2.png" alt="Conta de negociação">
                </a>
            </div>
        </section>
        <section *ngIf="step == 3">
            <div class="title">Acessar conta do Meta Trader 1/2</div>
            <div class="over-text">Para conectar a sua conta ao Meta trader, acesse a plataforma e siga os passos a seguir:</div> <br>
            <div class="over-text"><strong>a) </strong> Clique com o botão direito do Mouse sobre <span class="color-highlight">Contas</span></div>
            <div class="over-text"><strong>b) </strong>Em seguida clique em <span class="color-highlight">Abra uma conta</span></div>
            
            <div class="inline">
                <a href="assets/images/tutorial_install_metavision/Meta_11.png" target="_blank">
                    <img class="meta" src="assets/images/tutorial_install_metavision/Meta_11.png" alt="meta 1">
                </a>
            </div>

            <div class="over-text"><strong>c) </strong> Na lista de empresas digite ActivTrades Corp e clique em <span class="color-highlight">Encontre sua Empresa</span></div>
            <div class="over-text"><strong>d) </strong>Selecione a opção ActivTrades Corp</div>
            <div class="over-text"><strong>e) </strong>Clique em <span class="color-highlight">Avançar</span></div>
            
            <div class="inline">
                <a href="assets/images/tutorial_install_metavision/Meta_21.png" target="_blank">
                    <img class="meta" src="assets/images/tutorial_install_metavision/Meta_21.png" alt="meta 2">
                </a>
            </div>
        </section>
        <section *ngIf="step == 4">
            <div class="title">Acessar conta do Meta Trader 2/2</div>
            <div class="over-text">A janela que abriu para você, é a janela onde você irá informar o número da conta e senha que disponibilizamos para você em 
                <a [routerLink]="'/conta-negociacao'" target="_blank">Conta de negociação</a>.</div> <br>
            <div class="over-text"><strong>a) </strong> Selecione a opção <span class="color-highlight">Conectar-se a uma conta de negociação existente</span></div>
            <div class="over-text"><strong>b) </strong>Informe o número da conta e senha</div>
            <div class="over-text"><strong>c) </strong> Clique em <span class="color-highlight">Avançar</span></div>
            <div class="over-text"><strong>d) </strong> Agora que a sua conta está conectada. <span class="color-highlight">FECHE O META TRADER</span> para instalar o MetaVision nos próximos passos</div>
            
            <div class="inline img-center">
                <a href="assets/images/tutorial_install_metavision/Meta_31.png" target="_blank">
                    <img class="meta" src="assets/images/tutorial_install_metavision/Meta_31.png" alt="meta 3">
                </a>
            </div>
        </section>
        <section *ngIf="step == 5" >
            <div class="title">Instalar e configurar MetaVision 1/3</div>
            <div class="over-text">Antes de começar, clique no botão abaixo para baixar o instalador do Meta Vision, ele é
                uma ferramenta que será instalada na sua plataforma, e nos enviará as operações em tempo real para que você
                possa acompanhar o seu progresso em tempo real através da Dashboard.</div>
            <button class="button-primary" (click)="download_file()"><i class="fa-solid fa-download"></i>Baixar Meta
                Vision</button>

            <div class="over-text margin-text">Após realizar o download, <span class="color-highlight">execute o instalador</span></div> <br>
            <div class="over-text"><strong>a) </strong><span class="color-highlight">Selecione todas</span> as linhas que aparecer na janela que irá abrir.</div>
            <div class="over-text"><strong>b) </strong>Clique em <span class="color-highlight">Integrar</span></div>
            <div class="inline">
                <a href="assets/images/tutorial_install_metavision/step3.PNG" target="_blank">
                    <img class="step3" src="assets/images/tutorial_install_metavision/step3.PNG" alt="step 3">
                </a>
            </div>
        </section>
        <section *ngIf="step == 6">
            <div class="title">Instalar e configurar MetaVision 2/3</div>
            <div class="over-text">Após finalizar a integração do Meta Vision, abra novamente o Meta Trader 5, e siga os
                seguintes passos</div> <br>
            <div class="over-text"><strong>a) </strong>Habilite o <span class="color-highlight">Algotrading</span></div>
            <div class="over-text"><strong>b) </strong>Acesse a janela do <span class="color-highlight">Navegador</span> do Meta Trader,
                identifique o item <span class="color-highlight">Consultor expert</span> e clique no ícone '+' para expandir mais opções.</div>
            <div class="over-text"><strong>c) </strong>Após expandir as opções do Consultor expert, identifique o item
                <span class="color-highlight">MetavisionRealTime</span>, de um clique sobre ele e arraste para cima de um gráfico
            </div>
            <div class="color-warning-medium"><strong>ATENÇÃO 1:</strong> Recomendamos que você arraste o MetaVisionRealTime para o gráfico de Usatec (com vencimento) porque ele estará sempre aberto e em andamento. </div>
            <div class="over-text"><strong>d) </strong>Após arrastar o MetavisionRealTime para o gráfico, irá aparecer a
                janela de confirmação. Basta clicar no botão <span class="color-highlight">OK</span>.</div>
            <div class="color-warning-medium"><strong>ATENÇÃO 2:</strong> NÃO UTILIZE ESTE GRÁFICO PARA OPERAR. Após habilitar o MetaVisionRealTime no gráfico, <strong>deixe o gráfico minimizado ou em segundo plan</strong>o. O importante é deixar ele habilitado e operar em outros gráficos. </div>
            <div class="inline">
                <a href="assets/images/tutorial_install_metavision/step4.PNG" target="_blank">
                    <img class="step4" src="assets/images/tutorial_install_metavision/step4.PNG" alt="step 4">
                </a>
            </div>
        </section>
        <section *ngIf="step == 7">
            <div class="title">Instalar e configurar MetaVision 3/3</div>
            <div class="over-text">Esse último passo é um manual de como o MetaVision deverá estar ativo.</div> <br>
            <div class="over-text"><strong>a) </strong>O gráfico que você arrastou o MetaVisionRealTime, irá receber o ícone
                exibido na imagem abaixo. Se o ícone estiver azul, significa que a instalação foi realizada com sucesso.
            </div>
            <div class="over-text"><strong>b) </strong>E lembre-se que o gráfico que você escolheu para habilitar o MetaVision, precisa
                estar <strong>aberto</strong> ou em <strong>segundo plano</strong>, ou seja, ele não precisa estar visível
                na tela, desde que ele esteja em segundo plano e aparecendo conforme mostra a seta <strong>B</strong> da
                imagem a seguir.</div>
            <div class="inline">
                <a href="assets/images/tutorial_install_metavision/step5.PNG" target="_blank">
                    <img class="step5" src="assets/images/tutorial_install_metavision/step5.PNG" alt="step 5">
                </a>
            </div>
        </section>
        <section *ngIf="step == 8" class="step-finish">
            <img class="new-color" src="assets/icons/meta_install_finish.png" alt="correto">
            <div class="over-text margin-top">
                Parabéns <span class="color-highlight">{{user.name.split(' ')[0]}}</span>, você concluiu a instalação e configuração do MetaVision. Acesse a tela inicial para verificar o status do serviço. 
            </div> <br>
            <button class="button-primary" [routerLink]="'/home'">
                Voltar para o inicio
            </button>
        </section>
    </div>
    <div class="ft-buttons" *ngIf="step < 8">
        <div class="left-button">
            <div class="skip-step" *ngIf="step == 2">
                <div class="skip-text over-text">
                    Já conectou a conta que disponibilizamos para você?
                </div>
                <button class="button-secondary" (click)="skipStep()">
                    Pular para instalação do MetaVision
                </button>
            </div>
            <button class="button-secondary" *ngIf="step > 2" (click)="backStep()">
                Voltar
            </button>
        </div>
        <div class="right-button">
            <button class="button-primary" (click)="nextStep()">
                {{step < 3? 'Iniciar configurações': (step == 7? 'Finalizar':'Avançar')}}
            </button>
        </div>
    </div>
</div>